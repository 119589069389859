"use client";

import { useState } from "react";
import { BlockRenderer } from "@litonarefin/components/BlockRenderer";
import { isEmpty } from "lodash";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const Switcher = ({ attributes, blocks }) => {
    const [openPriceIndex, setOpenPriceIndex] = useState(0);
    // const allAOS = getAOSAnimation(attributes);

    return (
        <div
            className="jlt-flex jlt-flex-col jlt-justify-center"
            {...(attributes?.anchor && { id: attributes?.anchor })}>
            <div className="jlt-m-auto">
                <div className="jlt-bg-white jlt-p-1 jlt-inline-flex jlt-rounded-[40px] jlt-switcher-btns">
                    {attributes?.tabTitles?.map((btn, index) => (
                        <button
                            key={index}
                            onClick={() => setOpenPriceIndex(index)}
                            className={`jlt-rounded-[40px] jlt-shadow-[0px_0px_20px0_rgba(173,173,188,0.15)] jlt-text-sm jlt-font-bold jlt-py-3 jlt-px-8 jlt-border-none jlt-block jlt-cursor-pointer ${
                                openPriceIndex == index
                                    ? "jlt-bg-[#256eff] jlt-text-white"
                                    : "jlt-text-[#72798A]"
                            }`}>
                            {capitalizeFirstLetter(btn)}
                        </button>
                    ))}
                </div>
                <style jsx>{`
                    .jlt-switcher-btns {
                        ${!isEmpty(attributes?.switcherMargin?.desktop)
                            ? getDimensionCSS(attributes?.switcherMargin?.desktop, "margin")
                            : ""}
                    }
                `}</style>
            </div>
            <BlockRenderer blocks={blocks?.[openPriceIndex].innerBlocks} />
        </div>
    );
};

export default Switcher;
