"use client";

import { sanitize } from "@litonarefin/utils/miscellaneous";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState, Fragment, useEffect } from "react";
import { getIcon } from "@litonarefin/utils/icons";

const Code = ({ attributes }) => {
    const [copiedText, setCopiedText] = useState({ copied: false });

    useEffect(() => {
        let interval;
        if (copiedText?.copied === true) {
            interval = setInterval(() => {
                setCopiedText({ copied: false });
            }, 6000);
        }
        return () => clearInterval(interval);
    }, [copiedText]);

    return (
        <Fragment>
            <div {...(attributes?.anchor && { id: attributes?.anchor })} className="jlt-relative">
                {attributes?.className ? (
                    <code
                        className={attributes?.className}
                        dangerouslySetInnerHTML={{
                            __html: sanitize(attributes.content),
                        }}
                    />
                ) : (
                    <code
                        dangerouslySetInnerHTML={{
                            __html: sanitize(attributes.content),
                        }}
                    />
                )}
                <CopyToClipboard
                    text={`${attributes.content.replace(/<[^>]*>/g, "")}`}
                    onCopy={() => setCopiedText({ copied: true })}>
                    <button
                        className={`jlt-absolute jlt-top-1 jlt-right-[6px] jlt-border jlt-border-[#D0D5DD] jlt-py-2 jlt-px-4 jlt-rounded-[4px] jlt-text-xs jlt-flex jlt-items-center jlt-gap-2`}>
                        {copiedText?.copied ? (
                            <Fragment>{getIcon("check")}</Fragment>
                        ) : (
                            <Fragment>{getIcon("copy")}</Fragment>
                        )}
                    </button>
                </CopyToClipboard>
            </div>
        </Fragment>
    );
};

export default Code;
