import { getIcon } from "@litonarefin/utils/icons";

function Pagination({
    activePage,
    setActivePage,
    nextPage,
    setNextPage,
    prevPage,
    setPrevPage,
    totalPage,
}) {
    return (
        <div className="jlt-flex jlt-gap-2 jlt-items-center jlt-mt-4">
            <button
                disabled={prevPage < 1 ? true : false}
                onClick={() => {
                    setActivePage(prevPage);
                    setNextPage(prevPage + 1);
                    setPrevPage(prevPage - 1);
                }}
                className={`jlt-py-5 jlt-px-4 jlt-bg-[#fff] jlt-rounded-[2px] jlt-flex jlt-justify-center jlt-items-center ${
                    prevPage < 1 ? "jlt-cursor-not-allowed" : ""
                }`}>
                <span className="jlt-rotate-180">{getIcon("arrowRight")}</span>
            </button>

            {[...Array(Math.ceil(totalPage)).keys()]?.map((page, index) => (
                <button
                    key={index}
                    onClick={() => {
                        setNextPage(index + 2);
                        setPrevPage(index);
                        setActivePage(index + 1);
                    }}
                    className={`jlt-py-3 jlt-px-4 jlt-rounded-[2px] jlt-text-[#3D79F2] jlt-text-base jlt-font-bold jlt-flex jlt-justify-center jlt-shadow-[0px_0px_10px_rgba(173,173,188,0.1)] jlt-items-center ${
                        activePage === index + 1
                            ? `jlt-cursor-not-allowed jlt-text-white jlt-bg-[#3D79F2]`
                            : ""
                    }`}>
                    {index + 1}
                </button>
            ))}

            <button
                disabled={nextPage > Math.ceil(totalPage) ? true : false}
                onClick={() => {
                    setActivePage(nextPage);
                    setNextPage(nextPage + 1);
                    setPrevPage(nextPage - 1);
                }}
                className={`jlt-py-5 jlt-px-4 jlt-bg-[#fff] jlt-rounded-[2px] jlt-flex jlt-justify-center jlt-items-center ${
                    nextPage > Math.ceil(totalPage) ? "jlt-cursor-not-allowed" : ""
                }`}>
                {getIcon("arrowRight")}
            </button>
        </div>
    );
}

export default Pagination;
