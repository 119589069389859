import Img from "next/image";
import cx from "classnames";
import PropTypes from "prop-types";

export const Image = (props) => {
    const {
        alt,
        src,
        title,
        width,
        height,
        fill,
        blurDataURL = "/placeholder-image.jpg",
        className,
        containerClassNames,
        ...rest
    } = props;

    if (!src) {
        return null;
    }

    if (fill) {
        const attributes = {
            alt: alt || title,
            src: src,
            fill: true,
            blurDataURL,
            className: cx("object-cover", className),
            ...rest,
        };
        return <Img {...attributes} />;
        // return (
        //     <div className={cx("jlt-relative", containerClassNames)}>
        //         <Img {...attributes} />
        //     </div>
        // );
    } else {
        const attributes = {
            alt: alt || title,
            src: src,
            width: width || "auto",
            height: height || "auto",
            className,
            blurDataURL,
            ...rest,
        };
        return <Img {...attributes} />;
    }
};

Image.propTypes = {
    alt: PropTypes.string,
    title: PropTypes.string,
    src: PropTypes.string,
    fill: PropTypes.bool,
    containerClassName: PropTypes.string,
    className: PropTypes.string,
};

Image.defaultProps = {
    alt: "",
    title: "",
    src: "",
    containerClassNames: "",
    className: "jlt__image",
};
