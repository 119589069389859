// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";
// import Image from "next/image";
import Link from "next/link";

const DocsCard = ({ attributes }) => {
    const { title, description, btnText, btnLink, imageURL, imageAlt, docs } = attributes;

    // const allAOS = getAOSAnimation(attributes);

    return (
        <div
            {...(attributes?.anchor && { id: attributes?.anchor })}
            // {...allAOS}
            className="jlt-bg-[linear-gradient(124.09deg,#FFFFFF,90%,rgba(255,255,255,0)100%)] jlt-p-12 jlt-rounded-lg jlt-shadow-[0px_0px_45px_rgba(173,173,188,0.15)]">
            <div className="jlt-flex jlt-items-center jlt-gap-5 jlt-mb-12">
                {imageURL ? (
                    <div className="jlt-w-24 jlt-h-24">
                        <img src={imageURL} alt={imageAlt} width={96} height={96} />
                    </div>
                ) : null}
                <h2 className="jlt-text-[32px] jlt-font-bold jlt-text-[#101828]">{title}</h2>
            </div>

            <p className="jlt-text-lg jlt-text-[#344054]">{description}</p>

            <div className="jlt-mt-8">
                {docs?.map((doc, index) => (
                    <div key={index} className="jlt-flex jlt-items-center">
                        <svg
                            width="18"
                            height="17"
                            viewBox="0 0 18 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.4342 1.08813H1V15.6961H15.6079V10.8267"
                                stroke="#3D79F2"
                                strokeWidth="1.875"
                                strokewinecap="round"
                                strokewinejoin="round"
                            />
                            <path
                                d="M5.1748 8.04466L8.65288 11.5227L17.0003 2.47974"
                                stroke="#3D79F2"
                                strokeWidth="1.875"
                                strokewinecap="round"
                                strokewinejoin="round"
                            />
                        </svg>
                        <p className="jlt-ml-2 jlt-font-medium jlt-text-lg jlt-text-[#3f475a]">
                            {doc.label}
                        </p>
                    </div>
                ))}
            </div>

            <Link
                href={`${btnLink?.url || "/"}`}
                className="jlt-bg-[#3D79F2] jlt-py-4 jlt-px-6 jlt-rounded-full jlt-text-white jlt-text-sm jlt-font-bold jlt-mt-6 jlt-inline-block">
                {btnText}
            </Link>
        </div>
    );
};

export default DocsCard;
