"use client";

import { Fragment } from "react";

const Buttons = ({ attributes, children }) => {
    return (
        <Fragment>
            <div className="mb-btns">{children}</div>
            <style jsx>{`
                .mb-btns {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    ${attributes?.buttonsAlign
                        ? `justify-content: ${attributes?.buttonsAlign};`
                        : ""}
                    ${attributes?.buttonsGap ? `gap: ${attributes?.buttonsGap}px;` : "gap: 20px;"}
                }
                .mb-btns > a {
                    ${attributes?.buttonsWidth === "100%"
                        ? `flex-basis: ${attributes?.buttonsWidth};`
                        : ""}
                }
            `}</style>
        </Fragment>
    );
};

export default Buttons;
