"use client";

// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";
import { getBoxShadowCSS } from "@litonarefin/utils/getBoxShadowCSS";
import { Fragment } from "react";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";

const BgBanner = ({ attributes, children }) => {
    const {
        className,
        contentWidth,
        verticalPosition,
        contentShadow,
        contentBg,
        contentBorderRadius,
    } = attributes;

    const { boxShadow: contentBoxShadow } = getBoxShadowCSS(contentShadow);

    // const allAOS = getAOSAnimation(attributes);

    return (
        <Fragment>
            <section
                {...(attributes?.anchor && { id: attributes?.anchor })}
                className="section"
                // {...allAOS}
            >
                <div className={`bg-content${className ? ` ${className}` : ""}`}>{children}</div>
            </section>
            <style jsx>{`
                .section {
                    width: 100%;
                    position: relative;
                    ${getDimensionCSS(attributes?.sectionMargin, "margin")
                        ? getDimensionCSS(attributes?.sectionMargin, "margin")
                        : ""}
                    ${getDimensionCSS(attributes?.sectionPadding, "padding")
                        ? getDimensionCSS(attributes?.sectionPadding, "padding")
                        : ""}
                        ${attributes?.height ? `height: ${attributes?.height}px;` : ""}
                        ${attributes?.bgImageURL && attributes?.bgColor
                        ? `background: url(${attributes?.bgImageURL}), ${attributes?.bgColor};`
                        : ""}
                        ${!attributes?.bgImageURL && attributes?.bgColor
                        ? `background: ${attributes?.bgColor};`
                        : ""}
                        ${attributes?.bgImageURL && !attributes?.bgColor
                        ? `background: url(${attributes?.bgColor});`
                        : ""}
                        ${attributes?.bgImagePosition
                        ? `background-position: ${attributes?.bgImagePosition};`
                        : ""}
                        ${attributes?.bgImageRepeat
                        ? `background-repeat: ${attributes?.bgImageRepeat};`
                        : ""}
                        ${attributes?.bgImageSize
                        ? `background-size: ${attributes?.bgImageSize};`
                        : ""}
                        &::after {
                        clip-path: ${`polygon(100% ${
                            attributes?.polygonHeight || 0
                        }%, 0 100%, 100% 100%)`};
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -1px;
                        width: 100%;
                        height: 150px;
                        ${attributes?.polygonBgColor
                            ? `background-color: ${attributes?.polygonBgColor};`
                            : ""}
                    }
                }
                .section > .bg-content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 10;
                    margin: auto;
                    ${contentWidth ? `max-width: ${contentWidth};` : ""}
                    ${verticalPosition ? `top: ${verticalPosition}px;` : ""}
                    ${contentBg ? `background: ${contentBg};` : ""}
                    ${contentBorderRadius ? `border-radius: ${contentBorderRadius}px;` : ""}
                    ${contentBoxShadow ? contentBoxShadow : ""}
                }
            `}</style>
        </Fragment>
    );
};

export default BgBanner;
