"use client";

// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import { isEmpty } from "lodash";
import Image from "next/image";
import { Fragment } from "react";
import { LAPTOP, TABLET, MOBILE } from "@litonarefin/utils/breakpoint";

const Container = ({ attributes, children }) => {
    // const allAOS = getAOSAnimation(attributes);

    return (
        <Fragment>
            <section
                {...(attributes?.anchor && { id: attributes?.anchor })}
                className={`section${attributes?.className ? ` ${attributes?.className}` : ""}${
                    attributes?.pageFormat === "wpstandard" ? ` jlt-wstandard` : ""
                }`}>
                {attributes?.bgImageURL ? (
                    <Image
                        src={attributes?.bgImageURL}
                        alt={"bg image"}
                        fill={true}
                        sizes={"100vw"}
                        quality={50}
                        style={{
                            top: attributes?.bgPositionY ? `${attributes?.bgPositionY}px` : 0,
                            left: attributes?.bgPositionX ? `${attributes?.bgPositionX}px` : 0,
                            objectFit: attributes?.bgSize,
                            zIndex: 2,
                        }}
                    />
                ) : null}

                {attributes?.overlayColor ? <div className="jlt_overlay" /> : null}

                <div
                    className={`jt-container${
                        attributes?.containerClass ? ` ${attributes?.containerClass}` : ""
                    }`}>
                    {children}
                </div>
            </section>

            <style jsx>{`
                .section {
                    position: relative;
                    ${!isEmpty(attributes?.sectionMargin?.desktop)
                        ? getDimensionCSS(attributes?.sectionMargin?.desktop, "margin")
                        : ""}
                    ${!isEmpty(attributes?.sectionPadding?.desktop)
                        ? getDimensionCSS(attributes?.sectionPadding?.desktop, "padding")
                        : ""}
                        ${attributes?.bgColor ? `background: ${attributes?.bgColor};` : ""}
                        ${attributes?.overlayColor
                        ? `> .jlt_overlay {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 2;
                                    background: ${attributes?.overlayColor}
                                }`
                        : ""}
                }

                .section > .jt-container {
                    margin-right: auto;
                    margin-left: auto;
                    position: relative;
                    z-index: 2;
                    ${attributes?.maxWidth ? `max-width: ${attributes?.maxWidth}px;` : ""}
                    ${!isEmpty(attributes?.margin?.desktop)
                        ? getDimensionCSS(attributes?.margin?.desktop, "margin")
                        : ""}

                    ${!isEmpty(attributes?.padding?.desktop)
                        ? getDimensionCSS(attributes?.padding?.desktop, "padding")
                        : ""}
                    ${attributes?.verticalContainerPosition?.desktop
                        ? `top: ${attributes?.verticalContainerPosition?.desktop}px;`
                        : ""}
                    ${attributes?.horizontalContainerPosition?.desktop
                        ? `left: ${attributes?.horizontalContainerPosition?.desktop}px;`
                        : ""}
                }

                //laptop device
                @media only screen and (max-width: ${LAPTOP}px) {
                    .section {
                        ${!isEmpty(attributes?.sectionMargin?.laptop)
                            ? getDimensionCSS(attributes?.sectionMargin?.laptop, "margin")
                            : ""}
                        ${!isEmpty(attributes?.sectionPadding?.laptop)
                            ? getDimensionCSS(attributes?.sectionPadding?.laptop, "padding")
                            : ""}
                    }

                    .section > .jt-container {
                        ${!isEmpty(attributes?.margin?.laptop)
                            ? getDimensionCSS(attributes?.margin?.laptop, "margin")
                            : ""}
                        ${!isEmpty(attributes?.padding?.laptop)
                            ? getDimensionCSS(attributes?.padding?.laptop, "padding")
                            : ""}
                    }
                }

                //tablet device
                @media only screen and (max-width: ${TABLET}px) {
                    .section {
                        ${!isEmpty(attributes?.sectionMargin?.tablet)
                            ? getDimensionCSS(attributes?.sectionMargin?.tablet, "margin")
                            : ""}
                        ${!isEmpty(attributes?.sectionPadding?.tablet)
                            ? getDimensionCSS(attributes?.sectionPadding?.tablet, "padding")
                            : ""}
                    }
                    .section > .jt-container {
                        ${!isEmpty(attributes?.margin?.tablet)
                            ? getDimensionCSS(attributes?.margin?.tablet, "margin")
                            : ""}
                        ${!isEmpty(attributes?.padding?.tablet)
                            ? getDimensionCSS(attributes?.padding?.tablet, "padding")
                            : ""}
                    }
                }

                // mobile device
                @media only screen and (max-width: ${MOBILE}px) {
                    .section {
                        ${!isEmpty(attributes?.sectionMargin?.mobile)
                            ? getDimensionCSS(attributes?.sectionMargin?.mobile, "margin")
                            : ""}
                        ${!isEmpty(attributes?.sectionPadding?.mobile)
                            ? getDimensionCSS(attributes?.sectionPadding?.mobile, "padding")
                            : ""}
                    }
                    .section > .jt-container {
                        ${!isEmpty(attributes?.margin?.mobile)
                            ? getDimensionCSS(attributes?.margin?.mobile, "margin")
                            : ""}

                        ${!isEmpty(attributes?.padding?.mobile)
                            ? getDimensionCSS(attributes?.padding?.mobile, "padding")
                            : ""}

                        ${attributes?.verticalContainerPosition?.mobile
                            ? `top: ${attributes?.verticalContainerPosition?.mobile}px;`
                            : ""}
                        ${attributes?.horizontalContainerPosition?.mobile
                            ? `left: ${attributes?.horizontalContainerPosition?.mobile}px;`
                            : ""}
                    }
                }
            `}</style>
        </Fragment>
    );
};

export default Container;
