"use client";

import { getCleanSpacing } from "@litonarefin/utils/spacing";
import { Fragment } from "react";

function Separator({ attributes }) {
    return (
        <Fragment>
            <div className="jlt-separator"></div>
            <style jsx>{`
                .jlt-separator {
                    ${attributes?.className === "is-style-default"
                        ? `width: 100px; margin-left: auto; margin-right: auto;`
                        : ""}
                    border: 1px solid ${attributes?.style?.color?.background};
                    ${getCleanSpacing(attributes?.style?.spacing?.margin?.top)
                        ? `margin-top: ${getCleanSpacing(
                              attributes?.style?.spacing?.margin?.top
                          )}!important;`
                        : ""}
                    ${getCleanSpacing(attributes?.style?.spacing?.margin?.bottom)
                        ? `margin-bottom: ${getCleanSpacing(
                              attributes?.style?.spacing?.margin?.bottom
                          )};`
                        : ""}
                }
            `}</style>
        </Fragment>
    );
}

export default Separator;
